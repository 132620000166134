// src/pages/MarasandraPage/MarasandraPage.js

import React, { useState , useEffect } from 'react';
import axios from 'axios';
import { Card, Container, Form, Row, Col, Button } from 'react-bootstrap';
import { FaUserTie , FaAddressCard ,FaBuilding ,FaMoneyCheckAlt   } from 'react-icons/fa';
import './styles/OnlineApplication.css';

const OnlineApplication = () => {

  const [referenceName, setReferenceName] = useState('');
  const [referenceRank, setReferenceRank] = useState('');
  const [referenceServiceId, setReferenceServiceId] = useState('');
  const [referenceRelationship, setReferenceRelationship] = useState('');

  // State variables for Personal Details
  const [dob, setDob] = useState('');
  const [age, setAge] = useState('');
  const [personalName, setPersonalName] = useState('');
  const [fatherSpouseName, setFatherSpouseName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [paddress, setpAddress] = useState('');
  const [caddress, setcAddress] = useState('');
  const [waddress, setwAddress] = useState('');
  const [remark, setRemark] = useState('');
  const [propertyName] = useState('DEFENCE HABITAT');
  const [propertyType, setPropertyType] = useState('');
  const [propertySize, setPropertySize] = useState('');
  const [projectNames, setProjectNames] = useState([]);
  const [plotDimensions, setPlotDimensions] = useState([]);



  // nominee credentials 

  const [nomineeName, setnomineeName] = useState('');
  const [nomineeAge, setnomineeAge] = useState('');
  const [nomineeRelationship, setnomineeRelationship] = useState('');
  const [nomineeAddress, setnomineeAddress] = useState('');



  // Membership payment Details
  const [mempaymentMode, setmempaymentMode] = useState('');
  const [memBankname, setmemBankname] = useState('');
  const [memBranch, setmemBranch] = useState('');
  const [memPaydate, setmemPaydate] = useState('');
  const [memAmount] = useState('2500')
  const [memRefnum, setmemRefnum] = useState('');



  // State variables for Payment Details
  const [paymentMode, setPaymentMode] = useState('');
  const [bankName, setBankName] = useState('');
  const [branchName, setBranchName] = useState('');
  const [amount, setAmount] = useState('');
  const [amountInWords, setAmountInWords] = useState('');
  const [ddChequeRefNumber, setDdChequeRefNumber] = useState('');
  const [paymentType, setPaymentType] = useState('');

  const [formData, setFormData] = useState({
    referenceName: '',
    referenceRank: '',
    referenceServiceId: '',
    referenceRelationship: '',
    personalName: '',
    dob: '',
    age: '',
    fatherSpouseName: '',
    phone: '',
    email: '',
    paddress: '',
    caddress: '',
    waddress: '',
    remark: '',
    propertyName: '',
    propertyType: '',
    propertySize: '',
    nomineeName: '',
    nomineeAge: '',
    nomineeRelationship: '',
    nomineeAddress: '',
    mempaymentMode: '',
    memBankname: '',
    memBranch: '',
    memPaydate: '',
    memAmount: '',
    memRefnum: '',
    paymentType: '',
    paymentMode: '',
    bankName: '',
    branchName: '',
    amount: '',
    amountInWords: '',
    ddChequeRefNumber: '',
});



  useEffect(() => {
    // Fetch project names from API
    axios.get('http://localhost:5000/api/projectNames')
        .then(response => {
            setProjectNames(response.data);
        })
        .catch(error => {
            console.error('Error fetching project names:', error);
        });
}, []);


useEffect(() => {
  axios.get('http://localhost:5000/api/plotDimensions')
      .then(response => {
          setPlotDimensions(response.data);
      })
      .catch(error => {
          console.error('Error fetching plot dimensions:', error);
      });
}, []);

const handleInputChange = (event) => {
  const { name, value } = event.target;
  setFormData({
      ...formData,
      [name]: value
  });
};




const handleSubmit = async (e) => {
  e.preventDefault();

  const formData = {

    // reference details

    referenceName,
    referenceRank,
    referenceServiceId,
    referenceRelationship,


    // personal details 
    personalName,
    dob,
    age,
    personalName,
    fatherSpouseName,
    phone,
    email,
    paddress,
    caddress,
    waddress,
    remark,

    // property details


    propertyName,
    propertyType,
    propertySize,

    // nominee variables

    nomineeName,
    nomineeAge,
    nomineeRelationship,
    nomineeAddress,

    // MEMBERSHIP PAYMENT DETAILS
    mempaymentMode,
    memBankname,
    memBranch,
    memPaydate,
    memAmount,
    memRefnum,

    // PURCHASE OF SITE PAYMENT DETAILS :

    paymentType,
    paymentMode,
    bankName,
    branchName,
    amount,
    amountInWords,
    ddChequeRefNumber,
    paymentType
  };

  try {
    const response = await axios.post('http://localhost:5000/api/submit', formData);
    console.log(response.data);
    alert('Form submitted successfully');
  } catch (error) {
    console.error('Error submitting form', error);
    alert('Error submitting form');
  }
};

  const handleAmountChange = (e) => {
    const amount = e.target.value;
    setAmount(amount);

    // Convert amount to words (Indian numbering system)
    setAmountInWords(convertAmountToWords(amount));
  };


  const ones = [
    "", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine",
    "ten", "eleven", "twelve", "thirteen", "fourteen", "fifteen", "sixteen",
    "seventeen", "eighteen", "nineteen"
  ];
  
  const tens = [
    "", "", "twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"
  ];
  
  const scales = [
    "", "thousand", "lakh", "crore"
  ];
  
  const getHundreds = (num) => {
    let hundredPart = Math.floor(num / 100);
    let remainder = num % 100;
    let result = "";
  
    if (hundredPart > 0) {
      result += ones[hundredPart] + " hundred";
      if (remainder > 0) {
        result += " and ";
      }
    }
  
    if (remainder > 0) {
      if (remainder < 20) {
        result += ones[remainder];
      } else {
        result += tens[Math.floor(remainder / 10)];
        if (remainder % 10 > 0) {
          result += "-" + ones[remainder % 10];
        }
      }
    }
  
    return result;
  };

  const convertAmountToWords = (amount) => {
    if (amount === 0) return "zero";
  
    let result = "";
    let scaleIndex = 0;
  
    if (amount >= 10000000) {
      result += getHundreds(Math.floor(amount / 10000000)) + " crore ";
      amount = amount % 10000000;
    }
  
    if (amount >= 100000) {
      result += getHundreds(Math.floor(amount / 100000)) + " lakh ";
      amount = amount % 100000;
    }
  
    if (amount >= 1000) {
      result += getHundreds(Math.floor(amount / 1000)) + " thousand ";
      amount = amount % 1000;
    }
  
    if (amount > 0) {
      result += getHundreds(amount);
    }
  
    return result.trim();
  };

  const handleDobChange = (e) => {
    const selectedDate = new Date(e.target.value);
    const today = new Date();
    const calculatedAge = today.getFullYear() - selectedDate.getFullYear();
    setDob(e.target.value);
    setAge(calculatedAge);
  };

  const handlePaymentModeChange = (e) => {
    setPaymentMode(e.target.value);
  };



  return (
    <Container fluid className="online-application">
      <div className="banner">
        <div className="banner-content">
          <h1 style={{color:'white'}}>ಡಿಫೆನ್ಸ್ ಹ್ಯಾಬಿಟಾಟ್ ಹೌಸಿಂಗ್ ಕೋ-ಆಪರೇಟಿವ್ ಸೊಸೈಟಿ ಲಿ.</h1>
          <h1 style={{color:'white'}}>DEFENCE HABITAT HOUSING CO-OPERATIVE SOCIETY LTD. </h1>
          <h3>Reg. No.:- HSG-3/64/HHS/53744.</h3>
        </div>
      </div>

      <Form onSubmit={handleSubmit}>
        <Card className="property-card shadow-sm">
          <Card.Body>
            <div className="card-title application">
              <FaUserTie size={24} className="title-icon" />
              <h2>REFERENCE DETAILS :</h2>
            </div>
            <Row>
              <Col md={6}>
                <Form.Group controlId="formReferenceName">
                  <Form.Label className='form-fields'>Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter name" 
                  value={referenceName}
                  onChange={(e) => setReferenceName(e.target.value)}
                  required />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formReferenceRank">
                  <Form.Label className='form-fields'>Rank/Designation</Form.Label>
                  <Form.Control type="text" value={referenceRank} onChange={(e) => setReferenceRank(e.target.value)} placeholder="Enter rank/designation" required />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group controlId="formReferenceServiceId">
                  <Form.Label className='form-fields'>Service/ID No</Form.Label>
                  <Form.Control type="text" value={referenceServiceId} onChange={(e) => setReferenceServiceId(e.target.value)} placeholder="Enter service/ID no" required />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formReferenceRelationship">
                  <Form.Label className='form-fields'>Relationship With Applicant</Form.Label>
                  <Form.Control type="text" 
                  value={referenceRelationship}
                  onChange={(e) => setReferenceRelationship(e.target.value)}
                  placeholder="Enter relationship" required />
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Card className="property-card shadow-sm">
          <Card.Body>
            <div className="card-title application">
            <FaAddressCard size={24} className="title-icon" />
              <h2>PERSONAL DETAILS :</h2>
            </div>
            <Row>
              <Col md={4}>
                <Form.Group controlId="formPersonalName">
                  <Form.Label className='form-fields'>Name</Form.Label>
                  <Form.Control type="text" 
                  value={personalName}
                  onChange={(e) => setPersonalName(e.target.value)}
                  placeholder="Enter name" required />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formPersonalDob">
                  <Form.Label className='form-fields'>Date of Birth</Form.Label>
                  <Form.Control type="date" value={dob} 
                  onChange={handleDobChange} required />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formPersonalAge">
                  <Form.Label className='form-fields'>Age</Form.Label>
                  <Form.Control type="text" value={age} readOnly />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Form.Group controlId="formPersonalFatherSpouse">
                  <Form.Label className='form-fields'>Father's/Spouse Name</Form.Label>
                  <Form.Control type="text" 
                  value={fatherSpouseName}
                  onChange={(e) => setFatherSpouseName(e.target.value)}

                  placeholder="Enter father's/spouse name" 
                  required />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formPersonalPhone">
                  <Form.Label className='form-fields'>Phone No</Form.Label>
                  <Form.Control type="text" 
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="Enter phone number" 
                  required />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formPersonalEmail">
                  <Form.Label className='form-fields'>Email ID</Form.Label>
                  <Form.Control type="email" 
                  placeholder="Enter email" 
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group controlId="formPersonalPermanentAddress">
                  <Form.Label className='form-fields'>Permanent Address</Form.Label>
                  <Form.Control as="textarea" rows={3} 
                    value={paddress}
                    onChange={(e) => setpAddress(e.target.value)}
                    style={{resize:'none'}}

                  placeholder="Enter permanent address" 
                  required />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formPersonalCorrespondenceAddress">
                  <Form.Label className='form-fields'>Correspondence Address</Form.Label>
                  <Form.Control as="textarea" rows={3} 
                    value={caddress}
                    onChange={(e) => setcAddress(e.target.value)}
                    style={{resize:'none'}}
                  placeholder="Enter correspondence address" 
                  required />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group controlId="formPersonalWorkingAddress">
                  <Form.Label className='form-fields'>Working Address</Form.Label>
                  <Form.Control as="textarea" rows={3}  
                  value={waddress}
                  onChange={(e) => setwAddress(e.target.value)}
                  style={{resize:'none'}}
                  placeholder="Enter working address" required />
                  
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formPersonalRemark">
                  <Form.Label className='form-fields'>Remark, If Any</Form.Label>
                  <Form.Control as="textarea" rows={3}  
                  value={remark}
                  onChange={(e) => setRemark(e.target.value)}
                  style={{resize:'none'}}
                  placeholder="Enter remark" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group controlId="formPersonalPhoto">
                  <Form.Label className='form-fields'>Member Photo</Form.Label>
                  <Form.Control type="file" accept="image/*" required />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formPersonalSignaturePhoto">
                  <Form.Label className='form-fields'>Member Signature Photo</Form.Label>
                  <Form.Control type="file" accept="image/*" required />
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        {/* Property Details  */}

        <Card className="property-card shadow-sm">
          <Card.Body>
            <div className="card-title application">
              <FaBuilding size={26} className="title-icon" />
              <h2>PROPERTY DETAILS :</h2>
            </div>
            <Row>
              <Col md={4}>
                <Form.Group controlId="formPropertyName">
                  <Form.Label className='form-fields'>Property Name</Form.Label>
                  <Form.Control type="text" value="DEFENCE HABITAT" readOnly />
                </Form.Group>
              </Col>
              <Col md={4}>
              <Form.Group controlId="formPropertyType">
                                    <Form.Label className='form-fields'>Property Type</Form.Label>
                                    <div className="dropdown-input">
                                        <Form.Control as="select" defaultValue="">
                                            <option value="" disabled>Select property type</option>
                                            {projectNames.map((project) => (
                                                <option key={project.project_id} value={project.pro_name}>
                                                    {project.pro_name}
                                                </option>
                                            ))}
                                        </Form.Control>
                                        <div className="dropdown-symbol">
                                            <span>&#9660;</span>
                                        </div>
                                    </div>
                                </Form.Group>
              </Col>
              <Col md={4}>
              <Form.Group controlId="formPropertyLocation">
                                    <Form.Label className='form-fields'>Property Location</Form.Label>
                                    <div className="dropdown-input">
                                        <Form.Control as="select" defaultValue="">
                                            <option value="" disabled>Select property location</option>
                                            {plotDimensions.map(plot => (
                                                <option key={plot.plotsize_pk} value={plot.plotsize_pk}>
                                                    {plot.dimension}
                                                </option>
                                            ))}
                                        </Form.Control>
                                        <div className="dropdown-symbol">
                                            <span>&#9660;</span>
                                        </div>
                                    </div>
                                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>


        <Card className="property-card shadow-sm">
          <Card.Body>
            <div className="card-title application">
              <FaUserTie size={24} className="title-icon" />
              <h2>NOMINEE PARTICULARS :</h2>
            </div>
            <Row>
              <Col md={6}>
                <Form.Group controlId="formnomineeName">
                  <Form.Label className='form-fields'>Name</Form.Label>
                  <Form.Control type="text" 
                  value={nomineeName}
                  onChange={(e) => setnomineeName(e.target.value)}

                  placeholder="Enter name" 
                  required />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formnomineeAge">
                  <Form.Label className='form-fields'>Age</Form.Label>
                  <Form.Control type="text" 
                  value={nomineeAge}
                  onChange={(e) => setnomineeAge(e.target.value)}
                  placeholder="Enter Age" 
                  required />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group controlId="formnomineeRelationship">
                  <Form.Label className='form-fields'>Relationship </Form.Label>
                  <Form.Control 
                  type="text" 
                  value={nomineeRelationship}
                    onChange={(e) => setnomineeRelationship(e.target.value)}
                  placeholder="Enter Relationship" 
                  required />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formnomineeAddress">
                  <Form.Label className='form-fields'>Address </Form.Label>
                  <Form.Control type="text"
                  value={nomineeAddress}
                  onChange={(e) => setnomineeAddress(e.target.value)}
                   placeholder="Enter Address " 
                   required />
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
    {/* membership payment details  */}
    <Card className="property-card shadow-sm">
          <Card.Body>
            <div className="card-title application">
              <FaMoneyCheckAlt size={26} className="title-icon" />
              <h2>MEMBERSHIP PAYMENT DETAILS :</h2>
            </div>
            <Row>
              <Col md={4}>
                <Form.Group controlId="formPaymentMode">
                  <Form.Label className='form-fields'>Payment Mode</Form.Label>
                  <div className="dropdown-input">
                    <Form.Control as="select" 
                    value={mempaymentMode} 
                    onChange={(e) => setmempaymentMode(e.target.value)} 
                    required>
                      <option value="" disabled>Select payment mode</option>
                      <option value="DD">DD</option>
                      <option value="Cheque">Cheque</option>
                      <option value="Online">Online</option>
                    </Form.Control>
                    <div className="dropdown-symbol">
                      <span>&#9660;</span>
                    </div>
                  </div>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formBankName">
                  <Form.Label className='form-fields'>Bank Name</Form.Label>
                  <Form.Control type="text" 
                  placeholder="Enter bank name" 
                  value={memBankname}
                  onChange={(e) => setmemBankname(e.target.value)}
                  required />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formBranch">
                  <Form.Label className='form-fields'>Branch</Form.Label>
                  <Form.Control type="text" 
                  placeholder="Enter branch" 
                  value={memBranch}
                  onChange={(e) => setmemBranch(e.target.value)}
                  required />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Form.Group controlId="formPaymentDate">
                  <Form.Label className='form-fields'>Payment Date</Form.Label>
                  <Form.Control type="date" 
                  value={memPaydate}
                  onChange={(e) => setmemPaydate(e.target.value)}
                  required />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formAmount">
                  <Form.Label className='form-fields'>Amount</Form.Label>
                  <Form.Control type="text" value={2500} readOnly />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formReferenceNumber">
                  <Form.Label className='form-fields'>DD/Cheque/Reference Number</Form.Label>
                  <Form.Control type="text" 
                  placeholder="Enter DD/Cheque/Reference number"
                  value={memRefnum}
                  onChange={(e) => setmemRefnum(e.target.value)}
                  required />
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Card className="property-card shadow-sm">
          <Card.Body>
            <div className="card-title application">
              <FaMoneyCheckAlt size={26} className="title-icon" />
              <h2>PURCHASE OF SITE PAYMENT DETAILS :</h2>
            </div>
            <Row>
              <Col md={4}>
                <Form.Group controlId="formPaymentType">
                  <Form.Label className='form-fields'>Payment Type</Form.Label>
                  <div className="dropdown-input">
                  <Form.Control
                    as="select"
                    value={paymentType}
                    onChange={(e) => setPaymentType(e.target.value)}
                    required
                  >
                    <option>Select payment type</option>
                    <option>Site Advance</option>
                    <option>1st Installment</option>
                    <option>2nd Installment</option>
                    <option>3rd Installment</option>
                  </Form.Control>
                  <div className="dropdown-symbol">
                      <span>&#9660;</span>
                    </div>
                  </div>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formPaymentMode">
                  <Form.Label className='form-fields'>Payment Mode</Form.Label>
                  <div className="dropdown-input">
                  <Form.Control
                    as="select"
                    value={paymentMode}
                    onChange={(e) => setPaymentMode(e.target.value)}
                    required
                  >
                    <option>Select Payment</option>
                    <option>DD</option>
                    <option>Cheque</option>
                    <option>Online</option>
                  </Form.Control>
                  <div className="dropdown-symbol">
                      <span>&#9660;</span>
                    </div>
                  </div>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formBankName">
                  <Form.Label className='form-fields'>Bank Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter bank name"
                    value={bankName}
                    onChange={(e) => setBankName(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Form.Group controlId="formBranchName">
                  <Form.Label className='form-fields'>Branch Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter branch name"
                    value={branchName}
                    onChange={(e) => setBranchName(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formAmount">
                  <Form.Label className='form-fields'>Enter Amount</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter amount"
                    value={amount}
                    onChange={handleAmountChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formAmountInWords">
                  <Form.Label className='form-fields'>Amount in Words</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Amount in words"
                    value={amountInWords}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group controlId="formDdChequeRefNumber">
                  <Form.Label className='form-fields'>DD/Cheque/Reference Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter DD/Cheque/Reference number"
                    value={ddChequeRefNumber}
                    onChange={(e) => setDdChequeRefNumber(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
          <Row className="mt-3">
      <Col md={6} className="d-flex justify-content-between">
        <Button variant="primary" type="submit">Submit</Button>
        <Button variant="secondary" type="submit">Preview</Button>
      </Col>
    </Row>
        </Card>

      

        {/* <Button variant="primary" type="submit">Submit</Button> */}
      </Form>
    </Container>
  );
};

export default OnlineApplication;