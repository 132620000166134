import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button, Container, Card } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import './styles/DownloadBrochure.css';

const DownloadBrochure = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    address: '',
    authorization: false
  });

  const [formValidated, setFormValidated] = useState(false);
  const [isReadMore, setIsReadMore] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const { register, formState: { errors } } = useForm();
  const onSubmit = (data) => {
      console.log(data);
      alert('Form submitted successfully!');
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.name && formData.email && formData.mobile && formData.address && formData.authorization) {
      setFormValidated(true);
      handleDownload();
    } else {
      setFormValidated(false);
      alert('Please fill out all fields and agree to the authorization before downloading.');
    }
  };

  const handleDownload = () => {
    const pdfPath = `${process.env.PUBLIC_URL}/DHSBrochure.pdf`;
    saveAs(pdfPath, 'DHSBrochure.pdf');
  };

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <Container className="d-flex justify-content-center" style={{marginBottom:'2rem',marginTop:'3rem'}}>
      <Card className="download-brochure-card">
        <Card.Body>
          <h1>Download Brochure</h1>
          <Form className="download-brochure-form" onSubmit={handleSubmit}>
            <div className="form-grid">
              <Form.Group controlId="formName" className="form-group">
                <Form.Label className="form-label">Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="form-control"
                />
              </Form.Group>

              <Form.Group controlId="formEmail" className="form-group">
                <Form.Label className="form-label">Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="form-control"
                />
              </Form.Group>

              <Form.Group controlId="formMobile" className="form-group">
                <Form.Label className="form-label">Mobile</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your mobile number"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  required
                  className="form-control"
                />
              </Form.Group>

              <Form.Group controlId="formAddress" className="form-group">
                <Form.Label className="form-label">Address</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your address"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  required
                  className="form-control"
                />
              </Form.Group>
            </div>

            <div className="form-group checkbox-group">
  <label className="checkbox-label">
    <input
    style={{marginTop:'5px'}}
      type="checkbox"
      name="declaration"
      {...register('declaration', { required: 'You must declare' })}
    />
    <span className="checkbox-text">
      I hereby authorize Defence Habitat Housing Co-operative society Ltd, to contact me via phone and email regarding my enquiry. 
      {showMore ? (
        <span className="more-info">
          I understand that this communication may include follow-up calls, emails, and other messages to assist with my enquiry
          and provide further information about your services. This will override the
          registry on DND/NDNC. 
          <button type="button" onClick={() => setShowMore(false)} className="toggle-button" style={{color:'blue'}}>Read Less</button>
        </span>
      ) : (
        <span className="more-info">
          ... <button type="button" onClick={() => setShowMore(true)} className="toggle-button" style={{color:'blue'}} >Read More</button>
        </span>
      )}
    </span>
  </label>
  {errors.declaration && <p className="error-message">{errors.declaration.message}</p>}
</div>

            <div className="button-container">
              <Button
                variant="primary"
                type="submit"
                className="download-button"
                style={{ width: '195px' }}
              >
                Download Here
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default DownloadBrochure;