import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import './styles/ContactUs.css';
import location from '../images/qrcode.png';

const ContactUs = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = (data) => {
        console.log(data);
        alert('Form submitted successfully!');
    };

    const [showMore, setShowMore] = useState(false);

    return (
        <Container fluid className="contactus">
            <div className="banner-contact">
                <div className="banner-content-contact">
                    <h1 style={{color:'white',fontWeight:'bold'}}>Contact Us</h1>
                </div>
            </div>

            <Container fluid className="contact-content">
                <h2 style={{ textAlign: 'center' , fontSize:'25px' }}>"Get in touch with us today to find out how we can help you with all your real estate needs."</h2>
                <Row className="contact-form-details-row">
                    <Col md={6} className="contact-form-col">
                        <div className="contact-form-container">
                            <h2 style={{ textAlign: 'center', fontSize: '25px' }}>Get in Contact with Our Team</h2>
                            <form onSubmit={handleSubmit(onSubmit)} className="contact-form">
                                <div className="form-row">
                                    <div className="form-group">
                                        <label htmlFor="name">Name</label>
                                        <input
                                            id="name"
                                            name="name"
                                            type="text"
                                            {...register('name', { required: 'Name is required' })}
                                        />
                                        {errors.name && <p className="error-message">{errors.name.message}</p>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="phone">Phone Number</label>
                                        <input
                                            id="phone"
                                            name="phone"
                                            type="text"
                                            {...register('phone', { required: 'Phone number is required' })}
                                        />
                                        {errors.phone && <p className="error-message">{errors.phone.message}</p>}
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group">
                                        <label htmlFor="email">Email</label>
                                        <input
                                            id="email"
                                            name="email"
                                            type="email"
                                            {...register('email', {
                                                required: 'Email is required',
                                                pattern: { value: /^\S+@\S+$/i, message: 'Invalid email address' }
                                            })}
                                        />
                                        {errors.email && <p className="error-message">{errors.email.message}</p>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="subject">Subject</label>
                                        <input
                                            id="subject"
                                            name="subject"
                                            type="text"
                                            {...register('subject', { required: 'Subject is required' })}
                                        />
                                        {errors.subject && <p className="error-message">{errors.subject.message}</p>}
                                    </div>
                                </div>

                                <div className="form-group full-width">
                                    <label htmlFor="message">Message</label>
                                    <textarea
                                    style={{resize: "none"}}
                                        id="message"
                                        name="message"
                                        {...register('message', { required: 'Message is required' })}
                                    ></textarea>
                                    {errors.message && <p className="error-message">{errors.message.message}</p>}
                                </div>

                                <div className="form-group checkbox-group">
  <label className="checkbox-label">
    <input
    style={{marginTop:'5px'}}
      type="checkbox"
      name="declaration"
      {...register('declaration', { required: 'You must declare' })}
    />
    <span className="checkbox-text">
      I hereby authorize Defence Habitat Housing Co-operative society Ltd, to contact me via phone and email regarding my enquiry. 
      {showMore ? (
        <span className="more-info">
          I understand that this communication may include follow-up calls, emails, and other messages to assist with my enquiry
          and provide further information about your services. This will override the
          registry on DND/NDNC. 
          <button type="button" onClick={() => setShowMore(false)} className="toggle-button" style={{color:'blue'}}>Read Less</button>
        </span>
      ) : (
        <span className="more-info">
          ... <button type="button" onClick={() => setShowMore(true)} className="toggle-button" style={{color:'blue'}} >Read More</button>
        </span>
      )}
    </span>
  </label>
  {errors.declaration && <p className="error-message">{errors.declaration.message}</p>}
</div>

                                <button type="submit" className="submit-button">Send</button>
                            </form>
                        </div>
                    </Col>
                    <Col md={6} className="contact-details-col">
                        <Card className="info-card">
                            <Card.Body>
                                {/* <div className="contact-info-item">
                                    <FontAwesomeIcon icon={faMapMarkerAlt} size="2x" className="icon" />
                                    <p><strong>Address:</strong> Defence Habitat Housing Co-Operative Society Ltd.
                                        Behind Swathi Garden Hotel, E Block, Sahakarnagar, Bangalore. Karnataka - 560092.</p>
                                </div>
                                <div className="contact-info-item">
                                    <FontAwesomeIcon icon={faEnvelope} size="2x" className="icon" />
                                    <p><strong>Email:</strong> info@defencehousingsociety.com</p>
                                </div>
                                <div className="contact-info-item">
                                    <FontAwesomeIcon icon={faPhone} size="2x" className="icon" />
                                    <p><strong>Phone:</strong> 080 - 29903931</p>
                                    
                                </div> */}

<div className="contact-info-item">
 <h3> <FontAwesomeIcon icon={faMapMarkerAlt} size="2x" className="icon" />Address</h3>
 <p style={{textAlign:'center'}}>Defence Habitat Housing Co-Operative Society Ltd.
 Behind Swathi Garden Hotel, E Block, Sahakarnagar, Bangalore. Karnataka - 560092</p>
</div>

<div className="contact-info-item">
 <h3> <FontAwesomeIcon icon={faEnvelope} size="2x" className="icon" />Email</h3>
 <p style={{textAlign:'center'}} > <a href="mailto:info@defencehousingsociety.com" className='fontemail'> info@defencehousingsociety.com</a></p>
</div>
<div className="contact-info-item">
 <h3><FontAwesomeIcon icon={faPhone} size="2x" className="icon" />
 Phone</h3>
 <p style={{textAlign:'center'}}> <a href="tel:080-29903931" className='fontemail'>  080 - 29903931</a></p>
</div>






                            </Card.Body>
                        </Card>
                        <div className="scan-location">
                        <h2 className="scan-heading">SCAN  HERE  FOR <br/>  OFFICE LOCATION</h2>
                            <div className="scan-image">
                                <img src={location} alt="Office Location QR Code" />
                            </div>
                           
                        </div>
                    </Col>
                </Row>
                <Row className="map-row">
                    <Col md={12}>
                        <div className="map-address">
                          
                        </div>
                        <div className="map-container">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.61915046687!2d77.58879931482295!3d13.059896990797979!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTPCsDAzJzM1LjYiTiA3N8KwMzUnMjcuNiJF!5e0!3m2!1sen!2sin!4v1636363430335"
                                width="100%"
                                height="450"
                                style={{ border: 0 }}
                                allowFullScreen=""
                                loading="lazy"
                                title="Defence Habitat Location"
                            ></iframe>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default ContactUs;