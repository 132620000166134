// src/pages/MarasandraPage/MarasandraPage.js

import React from 'react';
import Marquee from 'react-fast-marquee';
import { Card, Container, Row, Col ,Table  } from 'react-bootstrap';
import './styles/latestnews.css';


const LatestNews = () => {
    
    return (
      <Container fluid className="marasandra-page">
        <div className="banner-latest">
          <div className="banner-content3">
            <h1 style={{color:'white',fontWeight:'bold'}}>Latest News</h1>
         
          </div>
        </div>
        <Marquee className="marquee" direction="left" speed={50}>
          <span>"Stay Tuned For The New Price Of Phase-2 Project "&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"Phase-2 Will Be Launching Soon."</span>
          {/* <span>Change of land and conversion in progress.</span> */}
        </Marquee>
        <Card className="property-card-latest shadow-sm">
          <Card.Body>
          <h2 className='ct'>Latest News</h2>
          <div className="title-divider"></div>
          <div className="title-divider"></div> 
          <h5 className='custom-bullet' style={{marginTop:"15px" , fontSize:'1rem' }}>  Defence Habitat Marasandra - Rs.1,399/- sqft (Booking Closed)</h5>
          <h5 className='custom-bullet' style={{marginTop:"15px" , fontSize:'1rem' }}>  Defence Habitat Tapasihalli - Rs.949/- sqft (Booking Closed)
          </h5>
          <h5 className='custom-bullet' style={{marginTop:"15px" , fontSize:'1rem' }}>  We are thrilled to announce the launch of Phase 2 of our project, bringing more opportunities and amenities to our valued Members!
          </h5>

          

          
         
          </Card.Body>
        </Card>

      </Container>
    );
  };

export default LatestNews;