import React from 'react';
import Marquee from 'react-fast-marquee';
import { Card, Container, Row, Col, Table } from 'react-bootstrap';
import './styles/TapasihalliPage.css';
import { FaRoad, FaTrain, FaShoppingCart, FaHospital, FaIndustry, FaBuilding, FaPlane, FaGlobe, FaUserTie } from 'react-icons/fa';
import waterImage from '../images/water.svg'
import urbanismImage from '../images/urbanism.svg'
import inspectionImage from '../images/inspection.svg'
import clubhouseImage from '../images/clubhouse.png'
import woodsImage from '../images/woods.svg'
import streetLightImage from '../images/street-light.svg'
import roadImage from '../images/road.svg'
import parkImage from '../images/park.svg'
import seal from '../images/Seal.png';
import ContactForm from './ContactForm'

const TapasihalliPage = () => {
  const amenities = [
    { id: 1, name: 'Water Connection', image: waterImage, backgroundColor: '#f0f0f0' },
    { id: 2, name: 'Electricity Connection', image: urbanismImage, backgroundColor: '#f5f5f5' },
    { id: 3, name: 'Sanitary Connection', image: inspectionImage, backgroundColor: '#f0f0f0' },
    { id: 4, name: 'Club House', image: clubhouseImage, backgroundColor: '#f5f5f5' },
    { id: 5, name: 'Tree Plantation', image: woodsImage, backgroundColor: '#f0f0f0' },
    { id: 6, name: 'Street Lights', image: streetLightImage, backgroundColor: '#f5f5f5' },
    { id: 7, name: 'Wide Black Top Roads', image: roadImage, backgroundColor: '#f0f0f0' },
    { id: 8, name: 'Garden', image: parkImage, backgroundColor: '#f5f5f5' },
  ];
  return (
    <Container fluid className="tapasihalli-page">
      <div className="banner-tapasihalli">
        <div className="banner-content-tapasihalli">
          <h1 style={{color:'white',  fontWeight:'bold', fontSize:'45px' ,fontFamily:'emoji'}}>DEFENCE HABITAT - TAPASIHALLI</h1>
          {/* <h1 style={{color:'white'}}>TAPASIHALLI</h1> */}
          <h3 style={{color:'white',  fontFamily:'emoji',fontSize:'28px'}}>North Bangalore , Doddaballapura .</h3>
        </div>
        <div className="stamps">
          <img src={seal} className='seals' alt="" title="" />
        </div>
      </div>
      <Marquee className="marquee" direction="left" speed={50}>
  <span>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"Work Will Be Executed As Per DPA Norms." &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; "Change Of Land Use Process Completed." &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; "Phase-2 Will Be Launching Soon."</span>
  {/* <span>Change of land and conversion in progress.</span> */}
</Marquee>

<div className='subintro'>
<p  >"Introducing Defence Habitat  Tapasihalli, an exceptional residential layout project located in the rapidly developing area of North Bangalore. Designed to meet the needs of modern living, our project offers an ideal blend of tranquility and connectivity, making it the perfect choice for discerning homebuyers and investors alike."</p>

</div>
      

      <Card className="property-card shadow-sm">
      <Card.Body>
        <div className="project-intro">
          {/* <h1 className="project-title">Project Tapasihalli</h1> */}
          <div className="intro-divider"></div>
          {/* <p>Welcome to Defence Habitat Tapasihalli, an exceptional residential layout project located in the rapidly developing area of North Bangalore. Designed to meet the needs of modern living, our project offers an ideal blend of tranquility and connectivity, making it the perfect choice for discerning homebuyers and investors alike.</p> */}
          <Row>
            <Col md={6}>
              <h2 className="intro-subtitle">Prime Location</h2>
              <div className="intro-divider"></div>
              <p style={{textAlign:'justify'}}>Situated in North Bangalore, Defence Habitat Tapasihalli is strategically positioned in close proximity to the Airport, upcoming 10,000 acres of <strong> ITIR/SEZ</strong> projects and <strong>KIADB</strong> areas, ensuring a thriving economic environment. This location not only provides convenient access to workplaces but also promises a significant appreciation in property value, making it a lucrative investment for the future.</p>
            </Col>
            <Col md={6}>
              <h2 className="intro-subtitle">Unmatched Connectivity</h2>
              <div className="intro-divider"></div>
              <p style={{textAlign:'justify'}} >Our project boasts excellent connectivity to key areas of Bangalore. With well-developed roads and upcoming infrastructure projects, residents can enjoy seamless travel to the city center, airports, educational institutions, healthcare facilities, and shopping destinations.</p>
            </Col>
          </Row>
        </div>
        <div >
        <p className="exclusive-text1" style={{textAlign:'center',fontSize:'15.5px'}}>
          The Exclusive part of Bangalore where land value, investor sentiment, business opportunities, and career prospects – all are heading north !
        </p>
        </div>
        <div className="title-divider"></div>
        <div className="title-divider"></div>
        {/* <p className="exclusive-text">
          The Exclusive part of Bangalore where land value, investor sentiment, business opportunities, and career prospects – all are heading north!
        </p> */}
        <Row>
          <Col md={6}>
            <ul className="icon-list">
              <li>
                <FaRoad className="icon" />
                <span style={{color:'black'}}>Close to 4 lane State Highway (SH-09).</span>
              </li>
              <li>
                <FaTrain className="icon" />
                <span style={{color:'black'}}>Close to Doddaballapura City & Railway Station.</span>
              </li>
              <li>
                <FaBuilding className="icon" />
                <span style={{color:'black'}}>RL Jalappa Institute of Technology .</span>
              </li>
              <li>
                <FaShoppingCart className="icon" />
                <span style={{color:'black'}}>Doddaballapura APMC Market  and CRPF Rakshak Enclave  .</span>
              </li>
            </ul>
          </Col>
          <Col md={6}>
            <ul className="icon-list">
              <li>
                <FaHospital className="icon" />
                <span style={{color:'black'}}>Near to Pushpanjali Maharshi Education Trust.</span>
              </li>
              <li>
                <FaIndustry className="icon" />
                <span style={{color:'black'}}>Close to Doddaballapura KIADB Industrial area.</span>
              </li>
              <li>
                <FaUserTie className="icon" />
                <span style={{color:'black'}}>Just a 10-minute drive to North Bangalore rural DC Office.</span>
              </li>
              <li>
                <FaGlobe className="icon" />
                <span style={{color:'black'}}>Near to 10,000 Acres ITIR/SEZ and 1,000 Acres Aerospace Hardware Park.</span>
              </li>
            </ul>
          </Col>
        </Row>
        <p className="booking-text blink"><strong style={{fontSize:'larger'}}>BOOKING CLOSED</strong></p>
            <p  className='booking-p' style={{fontFamily:'emoji'}}>Allotment of plots will be confirmed on 
              <b style={{fontFamily:'emoji'}}> 'First come first serve basis'.</b></p>
      </Card.Body>
    </Card>



       {/* Amenities Section */}
       <section className="amenities-section" style={{paddingTop:'10px'}}>
        <Container>
          <h2 style={{ textAlign: 'center', marginTop: '40px', marginBottom: '40px',color:'#24457b',fontWeight:'bold',fontSize:'30px' }}>Modern Amenities</h2>
          <p className="exclusive-text1" style={{
            fontSize:'18px',
            fontFamily:'emoji',
            fontWeight:'bold'
          }}>
          Defence Habitat - Tapasihalli is thoughtfully designed to offer a lifestyle of comfort and convenience. Our residential layout includes a range of modern amenities .
        </p>

          <Row>
            {amenities.map((amenity) => (
              <Col key={amenity.id} md={3} sm={6} className="mb-4">
                <Card className="amenity-card" style={{ backgroundColor: amenity.backgroundColor }}>
                  <Card.Img
                    className="amenity-img"
                    variant="top"
                    src={amenity.image}
                    style={{ width: '140px', height: '140px', objectFit: 'cover' }}
                  />
                  <Card.Body className='cardbody'>
                    <Card.Title className="card-title card-titles">{amenity.name}</Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>



      {/* Price Chart Section */}
      <section  style={{ backgroundColor: '#f8f6fe',  marginBottom: '40px', padding: '20px', borderRadius: '8px' }}>
        <Container>
          <h2 style={{ textAlign: 'center',  marginBottom: '40px',color:'#24457b',fontWeight:'bold',fontSize:'30px' }}>Price Chart</h2>
          <Table className="price-table" hover responsive >
            <thead>
              <tr >
                <th>Dimension</th>
                <th>Rate per Sqft</th>
                <th>Total Amount</th>
                <th>Down Payment 30%</th>
                <th>First Installment 30%</th>
                <th>Second Installment 20%</th>
                <th>Third Installment 20%</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{fontWeight:"bold"}}>30X40</td>
                <td>949/-</td>
                <td>11,38,800</td>
                <td>3,41,640</td>
                <td>3,41,640</td>
                <td>2,27,760</td>
                <td>2,27,760</td>
              </tr>
              <tr>
                <td style={{fontWeight:"bold"}}>30X50</td>
                <td>949/-</td>
                <td>14,23,500</td>
                <td>4,27,050</td>
                <td>4,27,050</td>
                <td>2,84,700</td>
                <td>2,84,700</td>
              </tr>
              <tr>
                <td style={{fontWeight:"bold"}}>40X60</td>
                <td>949/-	</td>
                <td>22,77,600</td>
                <td>6,83,280</td>
                <td>6,83,280</td>
                <td>4,55,520</td>
                <td>4,55,520</td>
              </tr>


            </tbody>
          </Table>
        </Container>

        {/* Terms and Conditions Section */}
        <section className="terms-conditions-section">
          <Container>
            <h2 style={{ textAlign: 'center',  marginBottom: '40px',color:'#24457b',fontWeight:'bold' ,fontSize:'30px' }}>Terms And Conditions</h2>
            <ul className="terms-listtt">
              <li>Corner sites will be charged extra.</li>
              <li>Registration charges extra as per government norms.</li>
              <li>Bank loans will be provided during the time of registration based on requirements from the members and subject to society norms.</li>
            </ul>
          </Container>
        </section>
      </section>



      {/* Bank Details Section */}
      <section className="bank-details-section">

        <Container>
          <Card className="bank-details-card shadow-sm">
            <Card.Body>
              <h2 style={{ textAlign: 'center',  marginBottom: '40px',color:'#24457b',fontWeight:'bold', fontSize:'30px' }}>Booking Details</h2>
              <ul className="booking-details-listt">
                <li>Application needs to be filled for obtaining Membership and Site booking along with 4 passport size photographs, address proof, and ID proof.</li>
                <li>Payment has to be paid in the mode of RTGS/NEFT, Cheque/Demand Draft (D.D) in favour of "Defence Habitat Housing Co-Operative Society Ltd."</li>
                <li>Buyers need to obtain Membership of the society by paying Rs.2,500/-.</li>
              </ul>
            </Card.Body>

            <Card.Body>
              <h2 style={{ textAlign: 'center',marginBottom: '40px',color:'#24457b',fontWeight:'bold' }}>Bank Details</h2>
              <div className="bank-details-table">
                <Table striped bordered hover responsive>
                  <tbody>
                    <tr>
                      <td style={{ textAlign: 'center' }}><strong>Account Name</strong></td>
                      <td style={{ textAlign: 'center' }}>DEFENCE HABITAT HOUSING COOPERATIVE SOCIETY LIMITED.</td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: 'center' }}><strong>Account Number</strong></td>
                      <td style={{ textAlign: 'center' }}>051388700000120</td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: 'center' }}><strong>Bank</strong></td>
                      <td style={{ textAlign: 'center' }}>YES BANK LTD.</td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: 'center' }}><strong>Branch</strong></td>
                      <td style={{ textAlign: 'center' }}>SAHAKAR NAGAR</td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: 'center' }}><strong>IFSC Code</strong></td>
                      <td style={{ textAlign: 'center' }}>YESB0000513</td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: 'center' }}><strong>Account Type</strong></td>
                      <td style={{ textAlign: 'center' }}>Current Account</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Card.Body>

            <Card.Body>
    <h2 style={{ textAlign: 'center', marginBottom: '40px', color:'#24457b', fontWeight:'bold', fontSize:'30px' }}>Process</h2>
    <ul className="booking-details-listt">
        <li>Payment to be made as per the Payment schedule.</li>
        <li>The buyer would be given a receipt and Site confirmation letter from the society confirming that, the application made towards purchasing the site.</li>
        <li>Customer will receive login ID and password through message or email which members can use to login web portal of society (department) and can find the details of their payment structure.</li>
        <li>The Society will send periodic payment reminders as per the payment schedule.</li>
        <li>After completion of the development process, the Society will send an allotment letter to buyers who have complied with their payments.</li>
        <li>Bookings of the plots will be accepted on 30% of the down payment of total sale value. The remaining balance amount has to be paid in 3 (Three) installments with the time period of 6 months for each installment.</li>
    </ul>
</Card.Body>



          </Card>
        </Container>


      </section>


      {/* <Card >
                <Card.Body>
                    <h2 className="text-center mb-4">Contact Us</h2>
                    <ContactForm />
                </Card.Body>
            </Card> */}


    </Container>
  );
};

export default TapasihalliPage;