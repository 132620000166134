
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import TapasihalliPage from './pages/TapasihalliPage'
import MarasandraPage from './pages/MarasandraPage'
import CustomNavbar from './components/Header/header';
import MainHeader from './components/MainHeader/mainheader';
import DownloadBrochure from './pages/DownloadBrochure'
import DownloadApplication from './pages/DownloadApplication'
import LatestNews from './pages/latestnews'
import ContactUs from './pages/ContactUs'
import FAQ from './pages/FAQ'
import Home from './pages/Home'
import AboutUs from './pages/AboutUs'
import Footer from './components/Footer/footer';
import OnlineApplication from './pages/OnlineApplication'
import 'bootstrap/dist/css/bootstrap.min.css';
import './pages/ScrollToTopButton'
import ScrollToTopButton from './pages/ScrollToTopButton';
import PrivacyPolicy from './pages/Privacypolicy'
function App() {
  return (
    <Router>
      <div>
        <MainHeader />
        <CustomNavbar /> 
        
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/projects/tapasihalli" element={<TapasihalliPage />} />
          <Route path="/projects/marasandra" element={<MarasandraPage />} />
          <Route path="/download/brochure" element={<DownloadBrochure />} />
          <Route path="/download/application" element={<DownloadApplication />} />
          <Route path="/latest-news" element={<LatestNews />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/online_application" element = {<OnlineApplication />}/>
          <Route  path="/PrivacyPolicy"   element={<PrivacyPolicy/>} />


        </Routes>
        
        <Footer /> {/* Footer component */}
        <ScrollToTopButton />
      </div>
    </Router>
  );
}

export default App;
